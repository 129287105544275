import { createSlice } from "@reduxjs/toolkit";
import loginService from "../services/login";
import bookService from "../services/books";
import songService from "../services/songs";

const loginSlice = createSlice({
  name: "login",
  initialState: null,
  reducers: {
    setLogin(state, action) {
      return action.payload;
    },
  },
});

export const { setLogin } = loginSlice.actions;

export const login = (credentials) => {
  return async (dispatch) => {
    const user = await loginService.login(credentials);
    window.localStorage.setItem("loggedUser", JSON.stringify(user));
    bookService.setToken(user.token);
    songService.setToken(user.token);
    dispatch(setLogin(user));
  };
};

export const logout = () => {
  return async (dispatch) => {
    window.localStorage.removeItem("loggedUser");
    bookService.setToken(null);
    songService.setToken(null);
    dispatch(setLogin(null));
  };
};

export const initializeLogin = () => {
  return async (dispatch) => {
    const loggedUserJSON = window.localStorage.getItem("loggedUser");
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON);
      bookService.setToken(user.token);
      songService.setToken(user.token);
      dispatch(setLogin(user));
    }
  };
};

export default loginSlice.reducer;
