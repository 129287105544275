import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { createBook } from "../reducers/bookReducer";

const BookForm = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [shortTitle, setShortTitle] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createBook({ title, shortTitle }));
    setTitle("");
    setShortTitle("");
  };

  return (
    <div>
      <h2>Lisää uusi kirja</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Kirjan nimi</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={title}
            onChange={({ target }) => setTitle(target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Lyhyt nimi</Form.Label>
          <Form.Control
            type="text"
            name="shortTitle"
            value={shortTitle}
            onChange={({ target }) => setShortTitle(target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Lisää kirja
        </Button>
      </Form>
    </div>
  );
};

export default BookForm;
