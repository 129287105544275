import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useState, forwardRef, useImperativeHandle } from "react";
import { createSong, updateSong } from "../reducers/songReducer";

const SongForm = ({ book, song }, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState(song ? song.title : "");
  const [number, setNumber] = useState(song ? song.number : "");
  const [arranger, setArranger] = useState(
    song && song.arranger ? song.arranger : ""
  );
  const [youtubeUrl, setYoutubeUrl] = useState(
    song ? (song.youtubeUrl ? song.youtubeUrl : "") : ""
  );
  const [spotifyUrl, setSpotifyUrl] = useState(
    song ? (song.spotifyUrl ? song.spotifyUrl : "") : ""
  );

  useImperativeHandle(ref, () => {
    return {
      setNumber,
    };
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    const yUrl = youtubeUrl.split("&")[0];
    const sUrl = spotifyUrl.split("?")[0];

    if (book) {
      dispatch(
        createSong({
          title,
          book,
          number,
          arranger,
          youtubeUrl: yUrl,
          spotifyUrl: sUrl,
        })
      );
    } else {
      dispatch(
        updateSong({
          id: song.id,
          title,
          number,
          arranger,
          youtubeUrl: yUrl,
          spotifyUrl: sUrl,
        })
      );
      navigate(-1);
    }
    setTitle("");
    setNumber("");
    setArranger("");
    setYoutubeUrl("");
    setSpotifyUrl("");
  };

  return (
    <div>
      <h3>{book ? "Lisää uusi veisu" : "Muokkaa veisua"}</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Veisun nimi</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={title}
            onChange={({ target }) => setTitle(target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Sovittaja</Form.Label>
          <Form.Control
            type="text"
            name="arranger"
            value={arranger}
            onChange={({ target }) => setArranger(target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Veisun numero</Form.Label>
          <Form.Control
            type="number"
            name="number"
            value={number}
            onChange={({ target }) => setNumber(target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Youtube-osoite</Form.Label>
          <Form.Control
            type="text"
            name="youtubeUrl"
            value={youtubeUrl}
            onChange={({ target }) => setYoutubeUrl(target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Spotify-osoite</Form.Label>
          <Form.Control
            type="text"
            name="spotifyUrl"
            value={spotifyUrl}
            onChange={({ target }) => setSpotifyUrl(target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Lähetä
        </Button>
      </Form>
    </div>
  );
};

export default forwardRef(SongForm);
