import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SongForm from "./SongForm";

const Song = () => {
  const { id } = useParams();

  const login = useSelector((state) => state.login);
  const song = useSelector((state) => state.songs.find((s) => s.id === id));

  if (!song) {
    return null;
  }

  return (
    <div>
      <h2>
        {song.title} ({song.book.shortTitle})
      </h2>
      {login && <SongForm song={song} />}
    </div>
  );
};

export default Song;
