import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BookForm from "./BookForm";

const Home = () => {
  const books = useSelector((state) => state.books);
  const login = useSelector((state) => state.login);

  if (!books) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Veisukirjat</h1>
      {books.map((book) => (
        <Link key={book.id} to={`/kirjat/${book.shortTitle}`}>
          <p>{book.title}</p>
        </Link>
      ))}
      {login && <BookForm />}
    </div>
  );
};

export default Home;
