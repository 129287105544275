import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { initializeBooks } from "./reducers/bookReducer";
import { initializeLogin } from "./reducers/loginReducer";
import { initializeSongs } from "./reducers/songReducer";
import Header from "./components/Header";
import Login from "./components/Login";
import Book from "./components/Book";
import Home from "./components/Home";
import Song from "./components/Song";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeBooks());
    dispatch(initializeLogin());
    dispatch(initializeSongs());
  }, [dispatch]);

  return (
    <div>
      <Router>
        <Header />
        <div className="container mt-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/kirjat/:shortTitle" element={<Book />} />
            <Route path="/veisut/:id" element={<Song />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
