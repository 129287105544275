import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./reducers/loginReducer";
import bookReducer from "./reducers/bookReducer";
import songReducer from "./reducers/songReducer";

export default configureStore({
  reducer: {
    login: loginReducer,
    books: bookReducer,
    songs: songReducer,
  },
});
