import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../reducers/loginReducer";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedUser = useSelector((state) => state.login);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(login({ username, password }));
    setUsername("");
    setPassword("");
    navigate("/");
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  if (loggedUser) {
    return (
      <Button variant="primary" type="submit" onClick={handleLogout}>
        Kirjaudu ulos
      </Button>
    );
  }

  return (
    <div>
      <h2>Kirjaudu sisään</h2>
      <Form onSubmit={handleLogin}>
        <Form.Group>
          <Form.Label>Käyttäjätunnus</Form.Label>
          <Form.Control
            type="text"
            name="username"
            value={username}
            onChange={({ target }) => setUsername(target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Salasana</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Kirjaudu sisään
        </Button>
      </Form>
    </div>
  );
};

export default Login;
