import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState, useEffect, createRef } from "react";
import SongForm from "./SongForm";
import SongList from "./SongList";

const Book = () => {
  const { shortTitle } = useParams();

  const formRef = createRef();
  const [songs, setSongs] = useState([]);
  const login = useSelector((state) => state.login);
  const book = useSelector((state) =>
    state.books.find((b) => b.shortTitle === shortTitle)
  );

  useEffect(() => {
    if (book) {
      setSongs([...book.songs].sort((a, b) => a.number - b.number));
    }
  }, [book]);

  useEffect(() => {
    if (songs.length > 0 && login) {
      formRef.current.setNumber(songs[songs.length - 1].number + 1);
    }
  }, [songs, formRef, login]);

  if (!book) {
    return null;
  }

  return (
    <div>
      <h2>{book.title}</h2>
      {login && <SongForm ref={formRef} book={book.id} />}
      <SongList songs={songs} />
    </div>
  );
};

export default Book;
