import { createSlice } from "@reduxjs/toolkit";
import bookService from "../services/books";

const bookSlice = createSlice({
  name: "books",
  initialState: [],
  reducers: {
    setBooks(_, action) {
      return action.payload;
    },
    addBook(state, action) {
      state.push(action.payload);
    },
    addSongToBook(state, action) {
      const book = state.find((b) => b.id === action.payload.book);
      book.songs.push(action.payload.newSong);
    },
    updateSongInBook(state, action) {
      const book = state.find((b) => b.id === action.payload.book.id);
      const song = action.payload.updatedSong;
      const index = book.songs.findIndex((s) => s.id === song.id);
      book.songs[index] = song;
    },
  },
});

export const { setBooks, addBook, addSongToBook } = bookSlice.actions;

export const initializeBooks = () => {
  return async (dispatch) => {
    const books = await bookService.getAll();
    dispatch(setBooks(books));
  };
};

export const createBook = (book) => {
  return async (dispatch) => {
    const newBook = await bookService.create(book);
    dispatch(addBook(newBook));
  };
};

export const updateSong = (updatedSong) => {
  return async (dispatch) => {
    console.log("updateSong", updatedSong);
    dispatch(
      bookSlice.actions.updateSongInBook({
        book: updatedSong.book,
        updatedSong,
      })
    );
  };
};

export default bookSlice.reducer;
