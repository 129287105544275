import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";

const Header = () => {
  const login = useSelector((state) => state.login);

  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand as={Link} to="/" className="ms-3">
          Veisukirja
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/kirjat/NSV2020">
              NSV 2020
            </Nav.Link>
            <Nav.Link as={Link} to="/kirjat/virsikirja">
              Virsikirja
            </Nav.Link>
            <Nav.Link as={Link} to="/kirjat/LT2018">
              Laulutuuli
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Nav className="me-3">
          <Nav.Link as={Link} to="/login">
            {login ? login.name : "Kirjaudu sisään"}
          </Nav.Link>
        </Nav>
      </Navbar>
    </div>
  );
};

export default Header;
