import { createSlice } from "@reduxjs/toolkit";
import { addSongToBook, updateSong as updateSongInBook } from "./bookReducer";
import songService from "../services/songs";

const songSlice = createSlice({
  name: "songs",
  initialState: [],
  reducers: {
    setSongs(_, action) {
      return action.payload;
    },
    addSong(state, action) {
      state.push(action.payload);
    },
    updateSong(state, action) {
      const song = action.payload;
      const index = state.findIndex((s) => s.id === song.id);
      state[index] = song;
    },
  },
});

export const { setSongs, addSong } = songSlice.actions;

export const initializeSongs = () => {
  return async (dispatch) => {
    const songs = await songService.getAll();
    dispatch(setSongs(songs));
  };
};

export const createSong = ({
  title,
  book,
  arranger,
  number,
  youtubeUrl,
  spotifyUrl,
}) => {
  const song = {
    title,
    book,
    arranger,
    number,
    youtubeUrl,
    spotifyUrl,
  };

  return async (dispatch) => {
    const newSong = await songService.create(song);
    dispatch(addSongToBook({ newSong, book }));
    dispatch(addSong(newSong));
  };
};

export const updateSong = ({
  id,
  title,
  arranger,
  number,
  youtubeUrl,
  spotifyUrl,
}) => {
  const song = {
    title,
    arranger,
    number,
    youtubeUrl,
    spotifyUrl,
  };

  return async (dispatch) => {
    const updatedSong = await songService.update(id, song);
    dispatch(updateSongInBook(updatedSong));
    dispatch(songSlice.actions.updateSong(updatedSong));
  };
};

export default songSlice.reducer;
