import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Table, Form, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faPenToSquare,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";

const SongList = ({ songs }) => {
  const login = useSelector((state) => state.login);

  const [search, setSearch] = useState("");

  return (
    <div>
      <Form>
        <Form.Group as={Row} className="mb-3" controlId="formNameFilter">
          <Form.Label column sm={2}>
            Hae veisuja
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Form.Group>
      </Form>
      <Table striped bordered>
        <thead>
          <tr>
            <th width="44px">#</th>
            <th>Veisu</th>
            <th>Sov.</th>
            <th width="111px">Linkit</th>
            {login && <th width="109px"></th>}
          </tr>
        </thead>
        <tbody>
          {songs
            .filter(
              (song) =>
                song.title.toLowerCase().includes(search.toLowerCase()) ||
                song.arranger?.toLowerCase().includes(search.toLowerCase())
            )
            .map((song) => (
              <tr key={song.id}>
                <td>{song.number}</td>
                <td>{song.title}</td>
                <td>{song.arranger}</td>
                <td>
                  <Button
                    className="me-2"
                    variant="secondary"
                    href={song.youtubeUrl}
                    disabled={!song.youtubeUrl}
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </Button>
                  <Button
                    variant="secondary"
                    href={song.spotifyUrl}
                    disabled={!song.spotifyUrl}
                  >
                    <FontAwesomeIcon icon={faSpotify} />
                  </Button>
                </td>
                {login && (
                  <td>
                    <Link to={`/veisut/${song.id}`}>
                      <Button className="me-2" variant="secondary">
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </Button>
                    </Link>
                    <Button variant="danger" disabled>
                      <FontAwesomeIcon icon={faXmarkCircle} />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SongList;
